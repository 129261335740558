/* Imports */
@import url(https://fonts.googleapis.com/css?family=Roboto:400|500);

/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-color: #73f5f8 #010104;
}

body {
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #010104;
  color: #333;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }
}
